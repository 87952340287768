import React, {useState, useEffect, useRef} from "react"
import {classNames, generateId} from "../../tools/react.dom.helpers";
import {_t} from "../../../../js/translation";
import Hint from "../hint";
import {Errors, Required, Text} from "../../ui-kit";
import moment from "moment";
import {Visible} from "../visible";
import {convertTime, getTimeFormat} from "../../../../common/utils";
import Config from "../../config";


export const TimeInput = ({label, id = generateId(), className, timezone, placeholder, value, onChange, errors, required, hintText, hintTitle, oneLine, times, dropdownPosition = TimeInput.DROPDOWN_POSITION.BOTTOM}) => {
    let [time, setTime] = useState(value ? value : undefined);
    let [isOpen, setOpen] = useState(false);
    let timeDropdown = useRef();
    let useTime = false;
    let firstTime = null;
    const availableData = times.filter(item => item.available === true);
    const groupedData = availableData.reduce((acc, item) => {
        let itemStart = moment(item.start).tz(timezone);
        const hour = itemStart.format('HH');
        if (!acc[hour]) {
            acc[hour] = [];
        }
        acc[hour].push(itemStart);
        if (!useTime && itemStart.format('HH:mm') === time) {
            useTime = true;
        }

        if (!firstTime) {
            firstTime = itemStart.format('HH:mm');
        }
        return acc;
    }, {});


    const sortedGroupedData = Object.entries(groupedData).sort(([hourA], [hourB]) => {
        if (hourA < hourB) return -1;
        if (hourA > hourB) return 1;
        return 0;
    });
    if (!useTime) {
        time = firstTime;
        setTime(time);
        onChange(time);
    }

    useEffect(() => {
        function handleClose(e) {
            if (timeDropdown.current && !timeDropdown.current.contains(e.target) && isOpen) {
                setOpen(false);
                e.stopPropagation();
            }
        }

        document.addEventListener('click', handleClose);
        return function cleanup() {
            document.removeEventListener('click', handleClose);
        };
    }, []);

    const setTimeAndCallOnChange = (startTime) => {
        setTime(startTime);
        onChange(startTime);
        setOpen(false);
    };

    const toggleOpen = () => {
        setOpen(!isOpen);
    };

    const formatHour = (hour) => {
        return moment(hour, 'HH').format(getTimeFormat(Config.user.time_format));
    };

    return <React.Fragment>
        <div className={classNames('form-group form-group--input-time', className, errors && '--error')}>
            <Visible visible={label}>
                <label htmlFor={id} className="label">
                    {_t(label)} {required && <Required/>}
                    {hintText ? <Hint title={hintTitle} text={hintText}/> : ''}
                </label>
            </Visible>

            <div className={classNames('field-container', oneLine && 'field-container--one-line')}>
                <input type="text"
                       className={classNames('form-control form-control-input--select', className && 'input--' + className)}
                       id={id}
                       placeholder={placeholder}
                       value={convertTime(time, Config.user.time_format)}
                       onClick={toggleOpen}
                       readOnly
                />
                <Visible visible={isOpen}>
                    <div className={classNames('input-time-dropdown', dropdownPosition && dropdownPosition)} ref={timeDropdown}>
                        {sortedGroupedData.map(([hour, startTimes]) => (
                            <div key={hour} className="input-time-dropdown__section">
                                <Text className="input-time-dropdown__section-title" color={Text.COLOR.SECONDARY}
                                      caption={formatHour(hour)}/>
                                <ul className="input-time-dropdown__list">
                                    {startTimes.map((startTime, index) => (
                                        <li key={index}
                                            className={classNames('input-time-dropdown__item', startTime.format('HH:mm') === time ? 'input-time-dropdown__item--selected' : '')}
                                            onClick={() => setTimeAndCallOnChange(startTime.format('HH:mm'))}>
                                            {startTime.format(getTimeFormat(Config.user.time_format))}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </Visible>
            </div>

            {errors && <Errors errors={errors}/>}
        </div>
    </React.Fragment>
};

TimeInput.DROPDOWN_POSITION = {
    BOTTOM: 'bottom',
    BOTTOM_RIGHT: 'bottom_right',
    TOP: 'top',
    TOP_RIGHT: 'top_right'
}