import React from "react";
import {childrenWithProps} from "../../tools/react.dom.helpers";
import Pane from "./pane";

//TODO change 'id' to something not confused with dom element's id
//NB be carefull to have some tab preselected at start
export default class MultiPane extends React.Component {

    childType = Pane;

    constructor(props) {
        super(props);
        this.state = {selectedPane: null};
    }

    render() {
        return <div className={'tab-container'}>
            {childrenWithProps(this.props.children, (child) => (
                child.type === this.childType ? {
                    // render pane component
                    visible: this.state.selectedPane ? this.state.selectedPane == child.props.id : child.props.visible
                } : {
                    // render switch/tab component
                    value: this.state.selectedPane || child.props.value,
                    onChange: (id) => this.selectPane(id)
                }
            ))}
        </div>
    }

    selectPane(id) {
        this.setState({selectedPane: id});
    }
}


